<template>
  <div>
    <div style="text-align: right">
      <router-link :to="`/syoung/shop/cash-out-list?ownCompanyId=${$route.query.ownCompanyId}`">
        <el-button type="text">查看退款申请记录</el-button>
      </router-link>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="160px"
      class="form"
    >
      <el-form-item label="账户主体：">
        <span>{{ accountCompanyInfo.subtypeValueName || '--' }}</span>
      </el-form-item>
      <el-form-item label="账户类型：" prop="withdrawAccountType">
        <el-radio-group v-model="form.withdrawAccountType" @change="changeRadio">
          <el-radio label="BALANCE">余额</el-radio>
          <el-radio label="DEPOSIT">保证金</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="可退款金额：">
        <span>¥{{ formaNumber(accountCompanyInfo.amount || 0, { type: 'thousands' }) }}</span>
      </el-form-item>
      <el-form-item label="退款金额：" prop="withdrawAmount">
        <el-input placeholder="请输入退款金额" v-model="form.withdrawAmount" type="number" class="input-with">
          <template slot="prepend">¥</template>
          <el-button slot="append" type="primary" @click="cashAll">全部退款</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="收款主体：" prop="contractInfoId">
        <el-select v-model="form.contractInfoId" filterable :placeholder="`有${companyInfoList.length}个主体可选择`" @change="getCompanyInfoId">
          <el-option
            v-for="item in companyInfoList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="isShow">
        <i class="el-icon-warning icon"></i>
        <span class="tips">暂无完整主体可用,可新增主体或补充主体的收付款信息</span>
      </el-form-item>
      <el-form-item label="收款账户：" prop="bankCardId">
        <el-select v-model="form.bankCardId" filterable placeholder="请先选择收款主体" @change="getBankAccountOptions">
          <el-option
            v-for="item in bankAccountOptions"
            :key="item.id"
            :label="`${item.cardholder}-${item.bankName}-${item.cardNumber}`"
            :value="item.id"
          >
            <span>{{ item.cardholder }}</span>-<span>{{ item.bankName }}</span>-<span>{{ item.cardNumber }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="isShowTip">
        <i class="el-icon-warning icon"></i>
        <span class="tips-warning">无可用账户，请前去维护收付款信息，</span>
        <el-button type="text" @click="addBank">去维护主体信息</el-button>
      </el-form-item>
      <template v-if="!!form.bankCardId">
        <el-form-item label="联行号：" prop="bankCode">
          <el-input v-model="form.bankCode" placeholder="请输入6-20位数字" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item prop="bankAttachmentJson">
          <ul slot="label" class="custom-label">
            <li>开户行许可证/</li>
            <li>收款银行确认函</li>
          </ul>
          <PictureCardCrypto accept=".doc,.docx,.pdf,.png,.jpeg,.jpg" :limit="2" :maxSize="2000 * 1024" v-model="form.bankAttachmentJson" :isHhow="false"></PictureCardCrypto>
          <div class="el-upload__tip">最多可以上传两张</div>
        </el-form-item>
        <el-form-item>
          <!-- 填写说明 -->
          <illustrateExplain />
        </el-form-item>
      </template>
      <el-form-item style="text-align: center">
        <el-button @click="onCancel">取 消</el-button>
        <el-button @click="onSubmit" type="primary">确 定</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
      title="请输入支付密码"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="20%"
    >
      <form @submit.prevent="accountPayPaid">
        <el-input size="large" v-model="payForm.payPassword" show-password></el-input>
        <div class="link" @click="forgetPassword">忘记密码</div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" native-type="submit" :loading="accountPayPaidLoading"
            >确 定</el-button
          >
        </div>
      </form>
    </el-dialog>
  </div>
</template>

<script>
import PictureCardCrypto from '@/components/Upload/PictureCardCrypto';
import illustrateExplain from '@/components/illustrate-explain';
import {
  distributorWithdrawRecordCreate,
  listCustomerAccountCompany,
  listAccountChargeContractInfo
} from '@/api/pay';
import { distributorWithdrawRecordIzWithdraw } from '@/api/shop';
import { formaNumber } from '@/utils/date';
import md5 from '@/common/js-md5/md5';
import { RSAencryptedData, EncryptedParam } from '@/common/rsa';
export default {
  name: 'cash-out',
  components: {
    PictureCardCrypto,
    illustrateExplain
  },
  data() {
    const validateWithdrawAmount = (rule, value, callback) => {
      if (!value || Number(value) === 0) {
        callback(`请输入金额`);
        return;
      }
      if (value < 0) {
        callback(`金额不能小于0`);
        return;
      }
      if (value > this.accountCompanyInfo.amount) {
        callback(`输入的金额已经超过可退款的金额`);
        return;
      }
      callback();
    };
    return {
      dialogFormVisible: false, // 支付密码弹窗
      isShow: false,
      isShowTip: false,
      accountPayPaidLoading: false,
      formaNumber,
      distributorId: '',
      amount: 0,
      payForm: {
        payPassword: '' // 支付密码
      },
      form: {
        withdrawAccountType: 'BALANCE',
        contractInfoId: '',
        bankCardId: '',
        withdrawAmount: '',
        bankCode: '',
        bankAttachmentJson: []
      },
      rules: {
        withdrawAccountType: [{ required: true, message: '必填信息', trigger: 'change' }],
        contractInfoId: [{ required: true, message: '必填信息', trigger: 'change' }],
        bankCardId: [{ required: true, message: '必填信息', trigger: 'change' }],
        withdrawAmount: [
          { required: true, message: '请输入退款金额', trigger: 'blur' },
          { validator: validateWithdrawAmount, trigger: 'blur' },
        ],
        bankCode: [{ required: true, message: '必填信息', trigger: 'blur' }],
        bankAttachmentJson: [{ required: true, message: '必填信息', trigger: 'blur' }],
      },
      accountOptionsList: [], // 收款信息
      companyInfoList: [], // 收款主体
      bankAccountOptions: [],
      accountCompanyInfo: {}
    };
  },
  computed: {
    
  },
  async created() {
    const res = await distributorWithdrawRecordIzWithdraw();
    if (!res.success || res.data !== 'true') {
      this.$router.replace('/syoung/shop/detail');
      this.$message.warning('暂时不支持退款功能');
      return;
    }
    this.init();
  },
  methods: {
    init() {
      this.getListCustomerAccountCompany();

      listAccountChargeContractInfo().then(res => {
        if (res?.data?.length) {
          this.accountOptionsList = res.data;
          this.companyInfoList = this.accountOptionsList.map(item => {
            const { merchantTypeName, contractInfoName } = item;
            const label = `${merchantTypeName}-${contractInfoName}`;
            return { label, value: item.id };
          });
        } else {
          this.isShow = true;
        }
      });
    },
    getListCustomerAccountCompany() {
      listCustomerAccountCompany({
        accountType: this.form.withdrawAccountType,
        subtypeValue: this.$route.query.ownCompanyId || ''
      }).then(res => {
        this.accountCompanyInfo = res.data?.[0] || {};
      });
    },
    // 获取账户信息
    changeRadio() {
      this.form.withdrawAmount = '';
      this.getListCustomerAccountCompany();
    },
    // 收款主体与收款账户联动
    getCompanyInfoId(id) {
      this.form.bankCode = '';
      this.form.bankCardId = '';
      this.form.bankAttachmentJson = [];
      this.isShowTip = false;
      const selectedItem = this.accountOptionsList.find(item => item.id === id);
      if (selectedItem) {
        this.bankAccountOptions = selectedItem?.bankCardVOList || [];
        // 收付款账户只有一个时默认选中
        if (this.bankAccountOptions.length === 1) {
          this.form.bankCardId = this.bankAccountOptions[0].id;
          this.getBankAccountOptions(this.bankAccountOptions[0].id);
        }
        if(!this.bankAccountOptions.length){
          this.isShowTip = true;
        }
      } else {
        this.bankAccountOptions = [];
      }
    },
    // 获取收款账户下对应的银行卡信息
    getBankAccountOptions(id) {
      const selectedItem = this.bankAccountOptions.find(item => item.id === id);
      if (selectedItem) {
        this.form.bankCode = selectedItem.bankCode;
        const attachmentJson = JSON.parse(selectedItem?.attachmentJson) || [];
        this.form.bankAttachmentJson = attachmentJson.map((item) => ({
          url: item
        }));
      }
    },
    // 提交
    accountPayPaid() {
      if(!this.payForm.payPassword){
        this.$message.error('支付密码不能为空');
        return;
      }
      this.accountPayPaidLoading = true;
      const md5Password = md5(this.payForm.payPassword); // md5加密
      const timestamp = Date.now(); // 时间戳
      const params = {
        payPassword: RSAencryptedData(md5Password), // rsa加密
        ...this.form,
        ownCompanyId: this.accountCompanyInfo?.subtypeValue,
        bankAttachmentJson: JSON.stringify(this.form.bankAttachmentJson.map(item => {
          return item.url;
        }))
      };
      delete params.id;
      distributorWithdrawRecordCreate(params, EncryptedParam(params, timestamp), timestamp)
      .then(() => {
        this.accountPayPaidLoading = false;
        this.$message.success('操作成功');
        this.$router.push('/syoung/shop/cash-out-list');
      })
      .finally(() => {
        this.accountPayPaidLoading = false;
      });
    },
    // 忘记密码
    forgetPassword() {
      this.$router.push('/syoung/information/change-pay-password');
    },
    addBank() {
      this.$router.replace('/syoung/shop/detail?activeName=second');
    },
    onCancel() {
      this.$router.replace('/syoung/shop/detail');
    },
    cashAll() {
      this.form.withdrawAmount = Number(this.accountCompanyInfo.amount);
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.payForm.payPassword = '';
          // 弹出输入支付密码弹窗
          this.dialogFormVisible = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  ::v-deep {
    .el-input,
    .el-select {
      width: 380px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
    .input-with {
      .el-input-group__append {
        background-color: #AB0033;
        border: 1px solid #AB0033;
        color: #fff;
      }
    }
    .accountRadio {
      .el-radio {
        .el-radio__input {
          display: none;
        }
        .el-radio__label {
          padding-left: 0;
        }
        &.is-checked {
          .account-info {
            border: 1px solid #ab0033;
          }
        }
      }
    }
  }

  .account-info {
    padding: 5px 10px;
    box-sizing: border-box;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    background: #f6f6f6;
    border-radius: 1px;
    width: 300px;

    &__container {
      display: flex;
    }
    &__label,
    &__content {
      display: inline-flex;
      align-items: baseline;
    }
    &__label:before {
      content: '*';
      color: #f56c6c;
      margin-right: 4px;
    }
    &__content {
      flex: 1;
      font-weight: 700;
      color: #303133;
      display: initial;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.icon {
  margin-right: 5px;
  font-size: 14px;
  color: #e83737;
}
.tips {
  color: #e83737;
  font-size: 12px;
}
.tips-warning {
  font-size: 12px;
}
.link{
  font-weight: 500;
  color: #409eff;
  text-align: right;
  cursor: pointer;
  margin-top: 5px;
}
</style>